import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'safe',
  standalone: true
})
export class SafeDomPipe implements PipeTransform {

  constructor(private _Sanitizer: DomSanitizer) { }

  transform(html: string): SafeHtml {
    return this._Sanitizer.bypassSecurityTrustHtml(html);
  }

}
